import './menu.scss';
import logo from '../../assests/maarava_wide_icon.jpg';
import React from "react";
import {ReactComponent as ShareIcon} from '../../assests/share-icon.svg';
import {ReactComponent as InfoIcon} from '../../assests/info-icon.svg';
import {ReactComponent as PowerOffIcon} from '../../assests/power-off.svg';
import {ReactComponent as FeedbackIcon} from '../../assests/feedback-icon.svg';
import {ReactComponent as JoinUs} from '../../assests/business-man-icon.svg';
import {ReactComponent as FavoriteIcon} from '../../assests/star-icon.svg';
import config from '../../config/config'
import {sendWhatsAppMessage, shareApp, getLogicalName} from '../../utils';
import { useHistory } from 'react-router-dom';
import {createAnalyticsEvent} from '../../firebase/firebase';


export default function Menu(props) {

    const {setOpen} = props;

    const history = useHistory();

    const logicName = getLogicalName();

    return (
        <div className={'app-menu-container'}>
            <div className={'app-menu-header'}>
                <img className={'logo-menu-header'} src={config.wideIcon} alt={''}/>
            </div>
            <div className={'app-menu-body'}>
                <div className={'menu-item-container'} onClick={()=>{
                    createAnalyticsEvent({eventName: 'menu-item-share', eventParams: {click: true}});
                    shareApp({title: config.title});
                }}>
                    <ShareIcon className={'menu-item-icon'}/>
                    <div className={'menu-item-text'}>שיתוף</div>
                </div>
                {/*<hr/>*/}
                <div className={'menu-item-container'} onClick={()=>{
                    createAnalyticsEvent({eventName: 'menu-item-favorites', eventParams: {click: true}});
                    history.push(`/${logicName}/favorites`);
                    setOpen(false);
                }}>
                    <FavoriteIcon className={'menu-item-icon'}/>
                    <div className={'menu-item-text'}>המועדפים שלי</div>
                </div>
                {/*<hr/>*/}
                <div className={'menu-item-container'} onClick={()=>{
                    createAnalyticsEvent({eventName: 'menu-item-feedback', eventParams: {click: true}});
                    sendWhatsAppMessage({msg: '*פידבק על האפליקצייה best-peoples:* : %0A', phoneNumber: config.appOwnerPhone})
                }}>
                    <FeedbackIcon className={'menu-item-icon'}/>
                    <div className={'menu-item-text'}>שלחו פידבק</div>
                </div>
                {/*<hr/>*/}
                <div className={'menu-item-container'} onClick={()=>{
                    if(config.googleFormAddTenantIframe) {
                        history.push(`/${logicName}/signup`);
                        setOpen(false);
                    }
                    else {
                        createAnalyticsEvent({eventName: 'menu-item-join-us', eventParams: {click: true}});
                        sendWhatsAppMessage({msg: '*אנא מלא את הפרטים האלו, ושלח:* : %0A תחום עסק:%0A שם העסק:  %0A שם העסק(באנגלית): %0A שם בעל העסק: %0A מהם השירותים הניתנים במסגרת העסק ? %0A מילות חיפוש (מילים שיעזרו לחיפוש החופשי למצוא את העסק):%0A מיקום מתן השירות (בית העסק/בית הלקוח/משלוחים):%0A כתובת העסק: %0A שעות פעילות: %0A טלפון: %0A אתר אינטרנט: %0A עמוד אינסטגרם: %0A עמוד פייסבוק: %0A *אנא צרפ/י מיקום מדויק של בית העסק%0A  *אנא צרפ/י תמונה של לוגו של בית העסק שלך', phoneNumber: config.addPeoplesPhone})
                    }
               }}>
                    <JoinUs className={'menu-item-icon'}/>
                    <div className={'menu-item-text'}>הצטרפו אלינו!</div>
                </div>
                {/*<hr/>*/}
                <div className={'menu-item-container'} onClick={()=>{
                    createAnalyticsEvent({eventName: 'menu-item-info', eventParams: {click: true}});
                    history.push(`/${logicName}/info`);
                    setOpen(false);
                }}>
                    <InfoIcon className={'menu-item-icon'}/>
                    <div className={'menu-item-text'}>אודות האפליקצייה</div>
                </div>
                {/*<hr/>*/}
                <div className={'menu-item-container'} onClick={()=>{
                    createAnalyticsEvent({eventName: 'menu-item-disconnect', eventParams: {click: true}});
                    window.localStorage.removeItem('logicName');
                    window.location.replace(`${window.location.origin}/choose-tenant`);
                }}>
                    <PowerOffIcon className={'menu-item-icon'}/>
                    <div className={'menu-item-text'}>התנתק</div>
                </div>
            </div>
            <div className={'app-menu-main-logo-container'}>
                <img className={'app-menu-main-logo'} src={config.squareIcon} alt={''}/>
            </div>
            <div className={'app-menu-footer'}>app version: {config.version}</div>
        </div>
    );
}