import React, {useState, useEffect} from "react";
import './professionalPage.scss';
import Loading from '../Loading/loading';
import { useHistory } from 'react-router-dom';
import ArrowBackBlackIcon from '../../assests/arrow_back_black.svg';
import ShareBlackIcon from '../../assests/share_black.svg';
import StarBlackIcon from '../../assests/star_black.svg';
import StarIconFill from '../../assests/star-filled-icon.svg';
import UserIcon from '../../assests/user-avatar.svg';
import LocationIcon from '../../assests/location.svg';
import PhoneIcon from '../../assests/phone.svg';
import FacebookIcon from '../../assests/facebook.svg';
import InstagramIcon from '../../assests/instagram.svg';
import WebIcon from '../../assests/web.svg';
import EmailIcon from '../../assests/email.svg';
import WhatsappIcon from '../../assests/whatsapp.svg';

import {sendWhatsAppMessage, shareApp, isMobile} from '../../utils';
import {addToFavorites, removeFromFavorites, isFavorite as isFavoriteFromLocalStorage} from '../../store/localStorageMgr'
import {createAnalyticsEvent} from '../../firebase/firebase';
import {getLogicalName} from '../../utils';
import Review from '../Review/Review';

import getIcon from '../../config/iconToCategoryMap';
import config from "../../config/config";

export default function ProfessionalPage(props) {
    const {id, loading, professionals} = props;

    const [showPopUp, setShowPopUp] = useState(false);
    const [isFavorite, setIsFavorite] = useState(isFavoriteFromLocalStorage({id}));

    const history = useHistory();

    if(loading) return <Loading/>

    const professional = professionals.find(f=>f.id === id);

    const logicName = getLogicalName();

    if(!professional){
        history.push(`/${logicName}`);
        return null;
    }

    const {businessName, ownerName, services, productLocation, address, phoneNumber, webLink, instagram, facebook, gps, activityTime, category, logoUrl, currentPosition, department, ministryLogo, email} = professional;


    document.title = businessName;
    document.querySelector('meta[name="description"]').setAttribute("content", businessName);


    // console.log("---professional=",professional)

    const isMobileMode = isMobile();

    const renderCategoryTeam= ()=>{
        return <div className={'business-logo-container'}>
            <img className={'user-logo-icon'} src={logoUrl || UserIcon} />
        </div>
    }

    const renderBusinessName = ()=>{
        if(!businessName) return null;
        return <div className={'business-name-container'}>
            { <img className={'user-logo-icon'} src={logoUrl || UserIcon} />}
            {businessName}
        </div>
    }

    const renderCategory = ()=>{
        if(!category) return null;
        return <div className={'business-category-container'}>
            {getIcon(category)}
            <span>{category}</span>
        </div>
    }

    const renderOwnerName = ()=>{
        if(!ownerName) return null;
        return <div className={'owner-name-container'}>
            <div>{ownerName}</div>
        </div>
    }


    const renderLocation = ()=>{
        if(!address && !gps) return null;
        return <div className={'business-key-value-container'}>
            <img src={LocationIcon}/>
            <span className={gps ? 'underline' : ''} onClick={()=> {
                if(gps){
                    const [latitude, longitude] = gps.split(',');
                    if(latitude && longitude){
                        createAnalyticsEvent({eventName: 'profession-page-move-to-waze', eventParams: {id, businessName, ownerName, category, latitude, longitude}});
                        window.open(isMobileMode ? `geo:${latitude},${longitude}` : `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank').focus();
                    }
                }
            }}>{address}</span>
        </div>
    }

    const renderPhone = ()=>{
        if(!phoneNumber) return null;

        let number;
        if(phoneNumber.startsWith('0')){
            number = phoneNumber.toString();
        }
        else{
            number = `0${phoneNumber}`
        }

        let phoneToView;
        if(number.length === 10){
            phoneToView = `${number.slice(0,3)}-${number.slice(3,number.length)}`;
        }else{
            phoneToView = `${number.slice(0,2)}-${number.slice(2,number.length)}`;
        }

        return <div className={'business-key-value-container'}>
            <img src={PhoneIcon}/>
            <span className={phoneNumber ? 'underline' : ''} onClick={()=> {
                createAnalyticsEvent({eventName: 'profession-page-phone-dialog-click', eventParams: {id, businessName, ownerName, category, number}});
                window.open(`tel:${number}`, '_blank').focus();
            }}>{phoneToView}</span>
        </div>
    }


    const renderMinistryLogo = ()=>{
        if(!ministryLogo) return null;
        return <div className={'ministry-logo-container'}>
            <img className={'ministry-logo-icon'} src={ministryLogo} />
        </div>
    }
    const renderServices = ()=>{
        if(!services) return null;
        const lines = services.split('.');
        return <div className={'services-container'}>
            {renderMinistryLogo()}
            <div className={'divider-services'}/>
            {
            lines.map(line=>{
                if(!line.trim()) return null;
                return <div>{line}{line.endsWith('.') ? '' : '.'}</div>
            })
        }
        </div>
    }


    const renderKeyValue = ({key, value})=>{
        if(!value) return null;
        return <div className={'product-category-container'}>
            <div className={'bold-key'}>{key}</div>
            <div className={'value-key'}>{value}</div>
        </div>
    }


    const renderButtonLinks = ()=>{
        const links = [];

        if(phoneNumber && phoneNumber.length === 9){
            links.push({
                icon: <img src={WhatsappIcon}/>,
                text: 'whatsapp',
                onClick: ()=> {
                    createAnalyticsEvent({eventName: 'profession-page-send-whatsapp', eventParams: {id, businessName, ownerName, category, phoneNumber}});
                    isMobileMode ? sendWhatsAppMessage({msg: `היי ${ownerName} !`, phoneNumber: `+972${phoneNumber}`}) : window.open(`https://api.whatsapp.com/send?phone=+972${phoneNumber}`, '_blank').focus();
                }
            })
        }

        if(webLink){
            links.push({
                icon: <img src={WebIcon}/>,
                text: 'אתר אינטרנט',
                onClick: ()=>{
                    createAnalyticsEvent({eventName: 'profession-page-move-to-website', eventParams: {id, businessName, ownerName, category, webLink}});
                    window.open(webLink, '_blank').focus();
                }
            })
        }

        if(facebook){
            links.push({
                icon: <img src={FacebookIcon}/>,
                text: 'facebook',
                onClick: ()=>{
                    createAnalyticsEvent({eventName: 'profession-page-move-to-facebook', eventParams: {id, businessName, ownerName, category, facebook}});
                    window.open(facebook, '_blank').focus();
                }
            })
        }

        if(email){
            links.push({
                icon: <img src={EmailIcon}/>,
                text: 'Email',
                onClick: ()=>{
                    createAnalyticsEvent({eventName: 'profession-page-move-to-email', eventParams: {id, businessName, ownerName, category, email}});
                    window.open(`mailto:${email}`, '_blank').focus();
                }
            })
        }

        if(instagram){
            links.push({
                icon: <img src={InstagramIcon}/>,
                text: 'instagram',
                onClick: ()=> {
                    createAnalyticsEvent({eventName: 'profession-page-move-to-instagram', eventParams: {id, businessName, ownerName, category, instagram}});
                    window.open(instagram, '_blank').focus();
                }
            })
        }

        return <React.Fragment>
            <div className={'bottom-links-container'}>
                {links.map(linkObj=><div className={'bottom-link-container'} onClick={linkObj.onClick}>
                    {linkObj.icon}
                </div>)}
            </div>
        </React.Fragment>
    }


    return (
       <div className={'professional-page-container'}>
           <div className={'professional-page-header'}>
               <div className={'right-panel'} onClick={()=>{
                   history.push(`/${logicName}/home/welcome/${category}`);
               }}>
                   <img src={ArrowBackBlackIcon}/>
               </div>
               <div className={'left-panel'}>
                   <img src={ShareBlackIcon} onClick={()=>{
                       createAnalyticsEvent({eventName: 'profession-page-share', eventParams: {id, businessName, ownerName, category}});
                       if(isMobileMode){
                           shareApp({suffix: id, title: config.title});
                       }else{
                           try {
                               navigator.clipboard.writeText(`${window.location.origin}/${id}`);
                               setShowPopUp(true);
                               setTimeout(()=>setShowPopUp(false), 2000)
                           }catch (e){
                               console.error(`failed to copy text: ${e}`);
                           }
                       }
                   }}/>
                   <div onClick={()=> {
                       createAnalyticsEvent({eventName: 'profession-page-favorite', eventParams: {id, businessName, ownerName, category, isFavorite}});
                       setIsFavorite(!isFavorite);
                       isFavorite ? removeFromFavorites({id}) : addToFavorites({id});
                   }}>{isFavorite ? <img src={StarIconFill}/> : <img src={StarBlackIcon}/>}</div>
               </div>

           </div>
           {showPopUp ? <div className={'pop-up-container'}>לינק הועתק!</div> : null}
           <div className={'professional-page-top-level'}>
               {renderCategoryTeam()}
               {renderBusinessName()}
               {renderCategory()}
               {renderOwnerName()}
               {renderLocation()}
               {renderPhone()}
           </div>
           <div className={'professional-page-body'}>
               {renderServices()}
               {renderKeyValue({key: "משרד:", value: department})}
               {renderKeyValue({key: "תפקיד נוכחי:", value: currentPosition})}
               {renderKeyValue({key: "מיקום:", value: productLocation})}
               {renderKeyValue({key: "שעות פעילות:", value: activityTime})}

               {/*<div className={'found-bug-container'}>*/}
               {/*    <div>מצאתם טעות?</div>*/}
               {/*    <button>לחצו כאן</button>*/}
               {/*</div>*/}
           </div>
           {renderButtonLinks()}
           {/*<Review id={id}/>*/}

       </div>
    );
}