import {ReactComponent as WorkshopsIcon} from '../assests/categories/workshop-icon.svg';
import {ReactComponent as GeneralIcon} from '../assests/categories/general-icon.svg';
import {ReactComponent as HearDesignIcon} from "../assests/categories/hair-design-icon.svg";
import {ReactComponent as FoodIcon} from "../assests/categories/food-icon.svg";
import {ReactComponent as StudyIcon} from "../assests/categories/study-icon.svg";
import {ReactComponent as ArchitectureIcon} from "../assests/categories/architecture-icon.svg";
import {ReactComponent as GraphicDesignIcon} from "../assests/categories/graphic-design-icon.svg";
import {ReactComponent as SportIcon} from "../assests/categories/sport-icon.svg";
import {ReactComponent as MedicineIcon} from "../assests/categories/medicine-icon.svg";
import {ReactComponent as CameraIcon} from "../assests/categories/camera-icon.svg";
import {ReactComponent as FinanceIcon} from "../assests/categories/israel-shekel-icon.svg";
import {ReactComponent as ElectronicsIcon} from "../assests/categories/electronics-icon.svg";
import {ReactComponent as AdviceIcon} from "../assests/categories/advice-icon.svg";
import {ReactComponent as GardeningIcon} from "../assests/categories/gardening-icon.svg";
import {ReactComponent as FashionIcon} from "../assests/categories/fashion-icon.svg";
import {ReactComponent as PregnancyIcon} from "../assests/categories/pregnancy-icon.svg";
import {ReactComponent as JudaismIcon} from "../assests/categories/judaism-icon.svg";
import {ReactComponent as HealthCareIcon} from "../assests/categories/health-care-icon.svg";
import {ReactComponent as PetsIcon} from "../assests/categories/pets-icon.svg";
import {ReactComponent as BicycleIcon} from "../assests/categories/bicycle-icon.svg";
import {ReactComponent as GroceryIcon} from "../assests/categories/grocery-icon.svg";
import {ReactComponent as PeopleIcon} from "../assests/categories/people-icon.svg";
import {ReactComponent as DrillIcon} from "../assests/categories/drill-idon.svg";
import {ReactComponent as CourtIcon} from "../assests/categories/court-icon.svg";
import {ReactComponent as TravelIcon} from "../assests/categories/travel-icon.svg";
import {ReactComponent as PartyIcon} from "../assests/categories/party-icon.svg";
import {ReactComponent as HealingIcon} from "../assests/categories/healing-hand-icon.svg";
import {ReactComponent as TeamIcon} from "../assests/categories/team.svg";
import {ReactComponent as GearIcon} from "../assests/categories/gear.svg";
import {ReactComponent as FoodVanIcon} from "../assests/categories/food-van.svg";
import {ReactComponent as DairyIcon} from "../assests/categories/dairy.svg";
import {ReactComponent as MeatIcon} from "../assests/categories/meat.svg";
import {ReactComponent as TheaterIcon} from "../assests/categories/theater.svg";
import {ReactComponent as RelationshipIcon} from "../assests/categories/relationship.svg";
import {ReactComponent as BrideIcon} from "../assests/categories/bride.svg";
import {ReactComponent as KnowledgeIcon} from "../assests/categories/knowledge.svg";
import {ReactComponent as InsuranceIcon} from "../assests/categories/insurance.svg";
import {ReactComponent as LawyerIcon} from "../assests/categories/lawyer.svg";
import {ReactComponent as BusinessmanCalculatorIcon} from "../assests/categories/businessmanCalculator.svg";
import {ReactComponent as WeddingIcon} from "../assests/categories/wedding.svg";
import {ReactComponent as EventIcon} from "../assests/categories/event.svg";
import {ReactComponent as MusicIcon} from "../assests/categories/music.svg";
import {ReactComponent as HumanResourceIcon} from "../assests/categories/human-resources.svg";
import {ReactComponent as MarketingIcon} from "../assests/categories/marketing.svg";

const iconToCategoryMap = {
    'עיצוב שיער' : ()=> <HearDesignIcon className={'button-category-icon'} />,
    'אוכל' : ()=> <FoodIcon className={'button-category-icon'}/>,
    'חינוך' : ()=> <StudyIcon className={'button-category-icon'}/>,
    'אדריכלות ועיצוב' : ()=> <ArchitectureIcon className={'button-category-icon'}/>,
    'אדריכלות' : ()=> <ArchitectureIcon className={'button-category-icon'}/>,
    'עיצוב גרפי, מיתוג ומתנות' : ()=> <GraphicDesignIcon className={'button-category-icon'}/>,
    'ספורט וחוגים' : ()=> <SportIcon className={'button-category-icon'}/>,
    'רפואה' : ()=> <MedicineIcon className={'button-category-icon'}/>,
    'צילום' : ()=> <CameraIcon className={'button-category-icon'}/>,
    'פיננסים' : ()=> <FinanceIcon className={'button-category-icon'}/>,
    'טכנאים ואלקטרוניקה' : ()=> <ElectronicsIcon className={'button-category-icon'}/>,
    'ייעוץ עסקי וליווי אישי' : ()=> <AdviceIcon className={'button-category-icon'}/>,
    'גינון' : ()=> <GardeningIcon className={'button-category-icon'}/>,
    'אופנה וטיפוח' : ()=> <FashionIcon className={'button-category-icon'}/>,
    'הריון ולידה' : ()=> <PregnancyIcon className={'button-category-icon'}/>,
    'יהדות ותשמישי קדושה' : ()=> <JudaismIcon className={'button-category-icon'}/>,
    'רפואה אלטרנטיבית' : ()=> <HealthCareIcon className={'button-category-icon'}/>,
    'חיות מחמד' : ()=> <PetsIcon className={'button-category-icon'}/>,
    'אופניים' : ()=> <BicycleIcon className={'button-category-icon'}/>,
    'מכולת' : ()=> <GroceryIcon className={'button-category-icon'}/>,
    'סדנאות והפעלות' : ()=> <WorkshopsIcon className={'button-category-icon'}/>,
    'שיפוצים ובניה' : ()=> <DrillIcon className={'button-category-icon'}/>,
    'משפטים' : ()=> <CourtIcon className={'button-category-icon'}/>,
    'תיירות' : ()=> <TravelIcon className={'button-category-icon'}/>,
    'אירועים' : ()=> <PartyIcon className={'button-category-icon'}/>,
    'טיפול ורפואה' : ()=> <HealingIcon className={'button-category-icon'}/>,
    'צוות האגודה' : ()=> <TeamIcon className={'button-category-icon'}/>,
    'צוות תוכנית ממשק' : ()=> <GearIcon className={'button-category-icon'}/>,
    "עמית ממשק מחזור יב'" : ()=> <div className={'button-category-icon'}>יב</div>,
    'עגלות קפה' : ()=> <FoodVanIcon className={'button-category-icon'}/>,
    'קייטרינג חלבי' : ()=> <DairyIcon className={'button-category-icon'}/>,
    'קייטרינג בשרי' : ()=> <MeatIcon className={'button-category-icon'}/>,
    'הצגות' : ()=> <TheaterIcon className={'button-category-icon'}/>,
    'יועצי זוגיות' : ()=> <RelationshipIcon className={'button-category-icon'}/>,
    'מדריכות כלה' : ()=> <BrideIcon className={'button-category-icon'}/>,
    'הרצאות השראה' : ()=> <KnowledgeIcon className={'button-category-icon'}/>,
    'סוכני ביטוח' : ()=> <InsuranceIcon className={'button-category-icon'}/>,
    'עו"ד' : ()=> <LawyerIcon className={'button-category-icon'}/>,
    'ביטוח ועורכי דין' : ()=> <LawyerIcon className={'button-category-icon'}/>,
    'רו"ח' : ()=> <BusinessmanCalculatorIcon className={'button-category-icon'}/>,
    'עורכי חופות' : ()=> <WeddingIcon className={'button-category-icon'}/>,
    'הפקת אירועים' : ()=> <EventIcon className={'button-category-icon'}/>,
    'נגנים ולהקות' : ()=> <MusicIcon className={'button-category-icon'}/>,
    'גיוס משאבים' : ()=> <HumanResourceIcon className={'button-category-icon'}/>,
    'שיווק דיגיטלי' : ()=> <MarketingIcon className={'button-category-icon'}/>,
    'הכל' : ()=> <PeopleIcon className={'button-category-icon'}/>,
    default: ()=> <GeneralIcon className={'button-category-icon'}/>
}

const getIcon = (category)=>{
    if(!category) return iconToCategoryMap.default();
    if(category && category.startsWith('מחזור')){
        return <div className={'button-category-icon'}>{category.split('מחזור')[1]}</div>
    }
    if(!iconToCategoryMap[category]) return iconToCategoryMap.default();
    return iconToCategoryMap[category]();
}

export default getIcon;