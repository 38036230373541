import React from "react";
import './info.scss';
import Loading from "../Loading/loading";
import { useHistory } from 'react-router-dom';
import { getLogicalName } from '../../utils';
import config from "../../config/config";


export default function Info(props) {
    const history = useHistory();

    const {loading, professionalsLength} = props;

    if(loading) return <Loading/>

    const logicName = getLogicalName();

    return (
            <div className={'info-container'} >
                <div className={'info-page-header'}>
                    <a onClick={()=>history.push(`/${logicName}`)} href={'#'}>המקצוענים שלנו</a>
                    {' > '}
                    אודות האפליקצייה
                </div>
                {config.appInfo({professionalsLength, logicName, history})}
            </div>

    );
}