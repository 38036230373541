import { getLogicalName } from '../utils';

import dynamicConfig from './dynamicConfig';
const staticConfig = {
    version: '1.0.14',
    appOwnerPhone: '+972543007218',
}



export default {... staticConfig, ... dynamicConfig[getLogicalName()]};